import { NavItem } from "src/types/NavItem";

const freelanceArtistPrimaryRoutes: NavItem[] = [
  {
    id: "casting-briefs",
    labelKey: "castingBriefs",
    href: "/casting-briefs",
  },
  {
    id: "view-profile",
    labelKey: "viewProfile",
    href: "/view-profile",
  },
  {
    id: "edit-profile",
    labelKey: "editProfile",
    href: "/edit-profile",
  },
  {
    id: "status-report",
    labelKey: "statusReport",
    href: "/status-report",
  },
  {
    id: "avail-checks",
    labelKey: "availChecks",
    href: "/avail-checks",
  },
  {
    id: "calendar",
    labelKey: "calendar",
    href: "/calendar",
  },
];

export default freelanceArtistPrimaryRoutes;
