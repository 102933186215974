import { useInterpret, useActor } from "@xstate/react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import navigationMachine from "src/machines/navigationMachine";
import { InterpreterFrom } from "xstate";
import { useApp } from "./AppContext";
import agencyRoutes from "src/constants/agencyPrimaryRoutes";
import adminPrimaryRoutes from "src/constants/adminPrimaryRoutes";
import artistPrimaryRoutes from "src/constants/artistPrimaryRoutes";
import basePrimaryRoutes from "src/constants/basePrimaryRoutes";
import { useRouter } from "next/router";

import {
  authenticatedHeaderMenuOptions,
  baseHeaderMenuOptions,
} from "src/constants/headerMenuOptions";
import * as altai from "src/services/altai";
import { toZendeskLocale } from "src/utils/toZendeskLocale";
import freelanceArtistPrimaryRoutes from "src/constants/freelanceArtistPrimaryRoutes";

interface NavigationProviderProps {
  children: ReactNode;
}

export type NavigationStateContextType = InterpreterFrom<
  typeof navigationMachine
>;

const NavigationContext = createContext({} as NavigationStateContextType);

const NavigationProvider = ({ children }: NavigationProviderProps) => {
  // App context
  const router = useRouter();
  const { appService } = useApp();
  const [appState, appSend] = useActor(appService);
  const isAuthenticated = appState.matches({
    authentication: { init: "authenticated" },
  });

  const parseMenuChangeHandler = (id: string) => {
    // TODO - Move the path mapping to the constants
    switch (id) {
      case "account":
        router.push("/account-details");
        break;
      case "my-profile":
        router.push("/edit-profile");
        break;
      case "support":
        if (isAuthenticated) {
          altai.getJwtLogin().then((jwt) => {
            if (appState.context.auth?.userType === "Agent") {
              var form = document.createElement("form");
              form.method = "POST";
              form.target = "_blank";
              form.action = `${
                process.env.NEXT_PUBLIC_ZENDESK_JWT_ENDPOINT
              }?jwt=${jwt}&return_to=${
                process.env.NEXT_PUBLIC_ZENDESK_HELPCENTER_AGENT
              }/hc/${toZendeskLocale(
                appState.context.agencyUserDetails?.defaultLocale
              )}`;
              document.body.append(form);
              form.submit();
            }
            if (
              appState.context.auth?.userType === "Artist" ||
              appState.context.auth?.userType === "FreelanceArtist"
            ) {
              var form = document.createElement("form");
              form.method = "POST";
              form.target = "_blank";
              form.action = `${
                process.env.NEXT_PUBLIC_ZENDESK_JWT_ENDPOINT
              }?jwt=${jwt}&return_to=${
                process.env.NEXT_PUBLIC_ZENDESK_HELPCENTER_ARTIST
              }/hc/${toZendeskLocale(
                appState.context.artistHomePageDetails?.defaultLocale
              )}`;
              document.body.append(form);
              form.submit();
            }
            if (appState.context.auth?.userType === "CastingDirector") {
              var form = document.createElement("form");
              form.method = "POST";
              form.target = "_blank";
              form.action = `${
                process.env.NEXT_PUBLIC_ZENDESK_JWT_ENDPOINT
              }?jwt=${jwt}&return_to=${
                process.env.NEXT_PUBLIC_ZENDESK_HELPCENTER_CASTINGDIRECTOR
              }/hc/${toZendeskLocale(
                appState.context.cdHomePageDetails?.defaultLocale
              )}`;
              document.body.append(form);
              form.submit();
            }
          });
        }
        break;
      case "help":
        break;
      case "profile":
        router.push("/artist-profile");
        break;
      case "sign-out":
        appSend({ type: "AUTH_LOGOUT" });
        break;
      default: {
        throw new Error(`Unknown menu id: ${id}`);
      }
    }
  };

  const service = useInterpret(navigationMachine, {
    devTools: false,
    actions: {
      notifyPrimary: () => {
        if (appState.context.auth?.userType === "Agent") {
          service.send({
            type: "SET_PRIMARY_NAV",
            list: agencyRoutes,
            menuOptions: authenticatedHeaderMenuOptions,
          });
          return;
        }
        if (appState.context.auth?.userType === "Altai") {
          service.send({
            type: "SET_PRIMARY_NAV",
            list: adminPrimaryRoutes,
            menuOptions: baseHeaderMenuOptions,
          });
          return;
        }
        if (appState.context.auth?.userType === "Artist") {
          const isRepresented =
            appState.context.artistHomePageDetails?.isRepresented ?? true;
          service.send({
            type: "SET_PRIMARY_NAV",
            list: isRepresented
              ? artistPrimaryRoutes.filter((x) => x.id != "avail-checks")
              : artistPrimaryRoutes,
            menuOptions: authenticatedHeaderMenuOptions,
          });
          return;
        }
        if (appState.context.auth?.userType === "FreelanceArtist") {
          const isRepresented =
            appState.context.artistHomePageDetails?.isRepresented ?? true;
          service.send({
            type: "SET_PRIMARY_NAV",
            list: isRepresented
              ? freelanceArtistPrimaryRoutes.filter(
                  (x) => x.id != "avail-checks"
                )
              : freelanceArtistPrimaryRoutes,
            menuOptions: authenticatedHeaderMenuOptions,
          });
          return;
        }
        if (appState.context.auth?.userType === "CastingDirector") {
          service.send({
            type: "SET_PRIMARY_NAV",
            list: basePrimaryRoutes,
            menuOptions: authenticatedHeaderMenuOptions,
          });
          return;
        }

        service.send({
          type: "SET_PRIMARY_NAV",
          list: basePrimaryRoutes,
          menuOptions: baseHeaderMenuOptions,
        });
      },
      notifyHeaderMenu: (ctx, evt) => {
        if (evt.type !== "HEADER_MENU_UPDATE") return;
        parseMenuChangeHandler(evt.id);
      },
    },
  });

  useEffect(() => {
    if (appState.context.artistHomePageDetails) {
      const isRepresented =
        appState.context.artistHomePageDetails?.isRepresented ?? true;
      service.send({
        type: "SET_PRIMARY_NAV",
        list:
          appState.context.auth?.userType === "FreelanceArtist"
            ? isRepresented
              ? freelanceArtistPrimaryRoutes.filter(
                  (x) => x.id != "avail-checks"
                )
              : freelanceArtistPrimaryRoutes
            : isRepresented
            ? artistPrimaryRoutes.filter((x) => x.id != "avail-checks")
            : artistPrimaryRoutes,
        menuOptions: authenticatedHeaderMenuOptions,
      });
    }
  }, [appState.context.artistHomePageDetails]);

  return (
    <NavigationContext.Provider value={service}>
      {children}
    </NavigationContext.Provider>
  );
};

const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (typeof context === "undefined") {
    throw new Error("useNavigation must be used within a NavigationProvider");
  }
  return context;
};

export { useNavigation, NavigationProvider };
