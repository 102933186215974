import UserType from "pages/data/[userType]";
import { AppContext, AppEvent } from "..";

export const hasPaymentStatus = (ctx: AppContext, evt: AppEvent): boolean =>
  ctx.paymentStatus != null;

export const userValidityForMessaging = (
  ctx: AppContext,
  evt: AppEvent
): boolean => ctx.auth.userType === "CastingDirector";

export const validateBillingInformation = (
  ctx: AppContext,
  evt: AppEvent
): boolean => {
  console.log(ctx.routerPath);
  return (
    ctx.auth.userType === "Altai" ||
    ctx.routerPath.includes("register") ||
    ctx.routerPath.includes("agency-invitation") ||
    (ctx.paymentStatus != null &&
      (ctx.paymentStatus?.isActive ||
        ctx.paymentStatus?.isTrial ||
        ctx.paymentStatus?.paymentExempt ||
        ((ctx.auth.userType === "Artist" ||
          ctx.auth.userType === "FreelanceArtist") &&
          !ctx.paymentStatus.isFirstLoginToLiteTier)))
  );
};

export const isAgencyUser = (ctx: AppContext, evt: AppEvent): boolean =>
  ctx.auth.userType === "Agent";

export const isArtist = (ctx: AppContext, evt: AppEvent): boolean =>
  ctx.auth.userType === "Artist" || ctx.auth.userType === "FreelanceArtist";

export const isCd = (ctx: AppContext, evt: AppEvent): boolean =>
  ctx.auth.userType === "CastingDirector";

export const isArtistFirstLoginAfterDowngrade = (
  ctx: AppContext,
  evt: AppEvent
): boolean =>
  ctx.paymentStatus != null &&
  (ctx.auth.userType === "Artist" || ctx.auth.userType === "FreelanceArtist") &&
  ctx.paymentStatus.isFirstLoginToLiteTier;
